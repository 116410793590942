import AuthInstance from "~/ts/interfaces/AuthInstance";
import { useTokenRefresher } from "~/composables/useTokenRefresher";
import JWTHelper from "~/ts/helpers/JWTHelper";
import { createFetch } from "~/ts/helpers/apiFetch";

export interface UseSubscriptionAuth {
  refreshTokenWhenNeeded(): Promise<void>;
  logout(): Promise<void>;
  activateCode(code: string): Promise<void>;
  tryAutomaticActivation(): Promise<void>;
  getCurrentSubscription(): { tools: string[] } | null;
}

export const useSubscriptionAuth: () => AuthInstance & UseSubscriptionAuth = () => {
  const apiFetch = createFetch();
  const subscriptionTokenStore = useTokenStore("subscriptions");

  const tokenRefresher = useTokenRefresher(async (access, refresh) => {
    const response = await apiFetch("/subscriptions/refresh", {
      method: "POST",
      jsonBody: {
        access_token: access,
        refresh_token: refresh,
      },
    })
      .then(r => r.json());

    return {
      access: response.access_token,
      refresh: response.refresh_token,
    };
  });

  return {
    isLoggedIn(): boolean {
      return subscriptionTokenStore.get("access") !== null;
    },

    async createAuthHeaders(): Promise<{ Authorization: string }> {
      await this.refreshTokenWhenNeeded();

      return {
        Authorization: `Bearer ${subscriptionTokenStore.get("access")}`,
      };
    },

    async refreshTokenWhenNeeded() {
      const access = subscriptionTokenStore.get("access");

      if (!access) {
        return;
      }

      const result = await tokenRefresher.refreshTokenWhenNeeded(
        access,
        <string> subscriptionTokenStore.get("refresh"),
      );

      if (!result) {
        this.logout();
        throw new Error();
      }

      subscriptionTokenStore.set("access", result.access);
      subscriptionTokenStore.set("refresh", result.refresh);
    },

    async activateCode(code: string) {
      const response = await apiFetch("/subscriptions/login", {
        method: "POST",
        jsonBody: {
          code,
        },
      })
        .then(r => r.json());

      subscriptionTokenStore.set("access", response.access_token);
      subscriptionTokenStore.set("refresh", response.refresh_token);
    },

    async logout() {
      subscriptionTokenStore.remove("access");
      subscriptionTokenStore.remove("refresh");
    },

    async tryAutomaticActivation() {
      if (this.isLoggedIn()) {
        return;
      }

      const response = await apiFetch("/subscriptions/automatic");

      if (!response.ok || response.status === 204) {
        return;
      }

      const contents = await response.json();

      subscriptionTokenStore.set("access", contents.access_token);
      subscriptionTokenStore.set("refresh", contents.refresh_token);
    },

    getCurrentSubscription() {
      const token = subscriptionTokenStore.get("access");

      if (!token) {
        return null;
      }

      return JWTHelper.getPayloadFromJWT<{ tools: string[] }>(token);
    },
  };
};
